import * as React from 'react';
import { styles, defaultTheme, withStyles } from '../settings/styles';
import { MuiThemeProvider, Typography, WithStyles, Button, CssBaseline, Paper, FormControl, InputLabel, Input, FormControlLabel, Checkbox } from '@material-ui/core';
import { translations } from '../settings/translations';
import { Redirect } from 'react-router';
import DB, { DBKey } from '../logic/db';


interface State
{
    keycode: string,
    resetcode: string,
    failed: boolean,
    failMsg: string,
    redirect: boolean,
    redirectUrl: string
}

// ----------------- Page ------------------  //

class Reset extends React.Component<WithStyles<typeof styles>, State>
{
    db: DB;

    constructor(props: any)
    {
        super(props);
        this.db = new DB();

        this.state = {
            keycode: "",
            resetcode: "",
            failed: false,
            failMsg: "",
            redirect: false,
            redirectUrl: ""
        }

        this.submit = this.submit.bind(this);
        this.back = this.back.bind(this);
        this.failMessage = this.failMessage.bind(this);
        this.handleResetKey = this.handleResetKey.bind(this);
    }

    render() 
    {
        const { classes } = this.props;
        const t = translations[defaultTheme.default.language];

        if(this.state.redirect) 
        {
            return <Redirect push to={this.state.redirectUrl} />
        }

        return (
        <MuiThemeProvider theme={defaultTheme}>
            <main className={classes.login_main}>
                
                <CssBaseline />
                <Paper className={classes.login_paper}>
                <Typography className={classes.login_title}>
                    {t.reset.title}
                </Typography>
                    <form className={classes.login_form}>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="code">{t.reset.input_productkey}</InputLabel>
                            <Input id="code" name="code" autoFocus value={this.state.keycode}
                                onChange={event=>{this.setState({keycode: event.target.value})}}/>
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="resetkey">{t.reset.input_resetkey}</InputLabel>
                            <Input name="resetkey" type="code" id="resetkey" value={this.state.resetcode}
                                onChange={event=>{this.setState({resetcode: event.target.value})}} />
                        </FormControl>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.register_submit}
                            onClick={this.submit}
                        >
                            {t.reset.submit_btn}
                        </Button>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.register_back}
                            onClick={this.back}
                        >
                            {t.reset.back_btn}
                        </Button>
                        {this.failMessage()}
                    </form>
                </Paper>
            </main>
        </MuiThemeProvider>
        );
    }

    failMessage()
    {
        const { classes } = this.props;
        if(this.state.failed)
        {
            return (
                <Typography className={classes.register_fail_msg}>
                    {this.state.failMsg}
                </Typography>
            );
        }
    }

    submit()
    {
        let params = {
            key: this.state.keycode,
            resetcode: this.state.resetcode
        }

        if( params.key !== '' &&
            params.resetcode !== '' )
        {
            this.setState({
                failed: false
            });
            this.db.freeKey(params, this.handleResetKey);
        }
        else
        {
            this.setState({
                failed: true,
                failMsg: "Alla fält som är markerade med * måste fyllas" // TODO
            });
        }
    }

    back()
    {
        this.setState({
            redirect: true,
            redirectUrl: "/Login"
        });
    }

    handleResetKey(key: DBKey)
    {
        console.log(key);
        if(key.keycode !== undefined)
        {
            this.setState({
                redirect: true,
                redirectUrl: "/Login"
            });
        }
        else
        {
            this.setState({
                failed: true,
                failMsg: "Produktnyckeln och återställningsnyckeln matchar inte" // TODO
            });
        }
    }
}

// ----------------- Export -----------------  //

export default withStyles(Reset);

// ------------------------------------------  //