// ======================================== //
//                                          //
//      Company:    Tar Valley              //
//      Author:     Sebastian Salonen       //
//                                          //
//                © 2019                    //
//                                          //
// ======================================== //

import * as React from 'react';
import { styles, defaultTheme, withStyles } from '../settings/styles';
import { MuiThemeProvider, Typography, WithStyles, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Fab } from '@material-ui/core';
import { translations } from '../settings/translations';
import KeyTable, { IKeyColumn } from '../components/key-table';
import DB, { DBKey } from '../logic/db';
import { Redirect } from 'react-router';
import ExitToApp from '@material-ui/icons/ExitToApp';

// -------------- State Data --------------- //

type State = {
  rows: IKeyColumn[];
  classes_stat: number;
  students_stat: number;
  dialog_open: boolean;
  dialog_header: string;
  dialog_content: string;
  dialog_callback: (confirmed:boolean)=>void;
  redirect: boolean,
  redirectUrl: string
};

// ----------------- Page ------------------ //

class AdminPanel extends React.Component<WithStyles<typeof styles>, State>
{
  //========================//
  //          Vars          //
  //========================//

  db: DB;
  translation: any;
  loginParams: any;

  //========================//
  //         Routine        //
  //========================//

  constructor(props: any)
  {
    super(props);

    // Auth
    this.db = new DB();
    this.loginParams = window.sessionStorage['uf-admin-login'] === undefined ? undefined : JSON.parse(window.sessionStorage['uf-admin-login']);
    let gotLogin = this.loginParams !== undefined && this.loginParams.username !== undefined && this.loginParams.password !== undefined;
    if(gotLogin)
    {
      this.db.fetchKeys(this.loginParams, this.handleKeyFetch.bind(this));
    }

    this.state = {
      rows: [],
      classes_stat: 0,
      students_stat: 0,
      dialog_open: false,
      dialog_header: "",
      dialog_content: "",
      dialog_callback: ()=>{},
      redirect: !gotLogin,
      redirectUrl: "/Admin"
    }

    this.translation = translations[defaultTheme.default.language];

    this.generateKey = this.generateKey.bind(this);
    this.claimKey = this.claimKey.bind(this);
    this.unclaimKey = this.unclaimKey.bind(this);
    this.deleteKey = this.deleteKey.bind(this);
    this.dialogOpen = this.dialogOpen.bind(this);
    this.logout = this.logout.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleDialogConfirm = this.handleDialogConfirm.bind(this);

    // window.addEventListener("beforeunload", (event: any) => {
    //   window.sessionStorage['uf-admin-login'] = "{}";
    // });
  }

  //========================//

  render() 
  {
    const { classes } = this.props;
    const t = this.translation;

    if(this.state.redirect) 
    {
        return <Redirect push to={this.state.redirectUrl} />
    }

    return (
      <MuiThemeProvider theme={defaultTheme}>
        <div className={classes.admin_root}>

          <Fab color="secondary" aria-label="Edit" className={classes.cp_logout} onClick={this.logout}>
            <ExitToApp/>
          </Fab>

          <Typography className={classes.admin_title}>
            {t.admin_panel.title}
          </Typography>

          <Typography className={classes.admin_subtitle}>
            {t.admin_panel.subtitle}
          </Typography>

          <Typography className={classes.admin_stat}>
            {t.admin_panel.classes_stat}: {this.state.classes_stat}
          </Typography>
          <Typography className={classes.admin_stat}>
            {t.admin_panel.students_stat}: {this.state.students_stat}
          </Typography>

          <br></br><br></br>

          <KeyTable 
            rows={this.state.rows}
            claim={this.claimKey}
            unclaim={this.unclaimKey}
            delete={this.deleteKey}
          />

          <Button variant="contained" color="primary" onClick={this.generateKey} className={classes.admin_generate_btn}>
            {t.admin_panel.generate_btn}
          </Button>

          <Dialog
            open={this.state.dialog_open}
            onClose={this.handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{this.state.dialog_header}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.state.dialog_content}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleDialogClose} color="secondary">
                {t.admin_panel.dialog_no}
              </Button>
              <Button onClick={this.handleDialogConfirm} color="primary" autoFocus>
                {t.admin_panel.dialog_yes}
              </Button>
            </DialogActions>
          </Dialog>

          <br></br><br></br><br></br>
        </div>
      </MuiThemeProvider>
    );
  }

  //========================//
  //      Button Logic      //
  //========================//

  generateKey(event:any)
  {
    this.db.createKey(this.loginParams, this.handleNewKey.bind(this));
  }

  //========================//

  claimKey(column: IKeyColumn)
  {
    const completeAction = () => {
      let params = this.loginParams;
      params['key'] = column.keycode;
      params['className'] = "Admin";
      params['teacherId'] = "Admin";
      this.db.claimKey(params, (key: DBKey) => {
        this.setState(state => {
          const rows = state.rows.map((row: IKeyColumn) => {
            if(row.keycode === key.keycode)
            {
              let today = new Date();
              row.claim_date = today.toISOString().split("T")[0];
            }
            return row;
          });
          return {
            rows,
          }
        });
      });
    };

    this.dialogOpen(
      this.translation.admin_panel.claim_header,
      this.translation.admin_panel.claim_description,
      (confirmed: boolean)=>{
        if(confirmed){
          completeAction();
        }
      }
    );
  }

  //========================//

  unclaimKey(column: IKeyColumn)
  {
    const completeAction = () => {
      let params = this.loginParams;
      params['key'] = column.keycode;
      this.db.freeKey(params, (key: DBKey) => {
        this.setState(state => {
          const rows = state.rows.map((row: IKeyColumn)=> {
            if(row.keycode === key.keycode)
            {
              row.claim_date = undefined;
              row.mail = "";
            }
            return row;
          });
          return {
            rows,
          }
        });
      });
    };

    this.dialogOpen(
      this.translation.admin_panel.unclaim_header,
      this.translation.admin_panel.unclaim_description,
      (confirmed: boolean)=>{
        if(confirmed){
          completeAction();
        }
      }
    );
  }

  //========================//

  deleteKey(column: IKeyColumn)
  {
    const completeAction = () => {
      let params = this.loginParams;
      params['key'] = column.keycode;
      this.db.deleteKey(params, (key: DBKey) => {
        const {rows} = this.state;
        for(let i = 0; i < rows.length; i++)
        {
          if(rows[i].keycode === column.keycode)
          {
            rows.splice(i, 1);
            break;
          }
        }
        this.setState({
          rows: rows
        });
      });
    };

    this.dialogOpen(
      this.translation.admin_panel.delete_header,
      this.translation.admin_panel.delete_description,
      (confirmed: boolean)=>{
        if(confirmed){
          completeAction();
        }
      }
    );
  }

  //========================//

  dialogOpen(header: string, content: string, callback: (confirmed:boolean)=>void)
  {
    this.setState({ 
      dialog_open: true,
      dialog_header: header,
      dialog_content: content,
      dialog_callback: callback
    });
  }

  //========================//

  logout()
  {
    window.sessionStorage['uf-admin-login'] = "{}";
    this.setState({
      redirect: true,
      redirectUrl: "/Admin"
    })
  }

  //========================//
  //        Callbacks       //
  //========================//

  handleNewKey(key: DBKey)
  {
    const { rows } = this.state;
    let row: IKeyColumn = {
      id: key.id,
      keycode: key.keycode, 
      resetcode: key.resetcode,
      class_id: key.class_id,
      create_date: key.created,
      claim_date: key.claimed === 'No' ? undefined : key.claimed
    } as IKeyColumn;
    rows.push(row);
    this.setState({
      rows: rows
    });
  }

  //========================//

  handleKeyFetch(keys: DBKey[])
  {
    let rows:IKeyColumn[] = [];
    keys.forEach(key => {
      rows.push({
        id: key.id,
        mail: key.mail === 'No' ? undefined : key.mail,
        keycode: key.keycode,
        resetcode: key.resetcode,
        class_id: key.class_id,
        create_date: key.created,
        claim_date: key.claimed === 'No' ? undefined : key.claimed
      } as IKeyColumn)
    });
    this.setState({
      rows: rows
    });

    this.db.fetchRegisteredClasses(this.loginParams, (count: string)=>{
      this.setState({
        classes_stat: parseInt(count)
      });
    });
    this.db.fetchRegisteredStudents(this.loginParams, (count: string)=>{
      this.setState({
        students_stat: parseInt(count)
      });
    })
  }

  //========================//

  handleDialogClose()
  {
    this.setState({
      dialog_open: false
    });
    this.state.dialog_callback(false);
  }

  //========================//

  handleDialogConfirm()
  {
    this.setState({ 
      dialog_open: false
    });
    this.state.dialog_callback(true);
  }

  //========================//

}

// ----------------- Export -----------------  //

export default withStyles(AdminPanel);

// ------------------------------------------  //