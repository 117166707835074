import * as React from 'react';
import { styles, defaultTheme, withStyles } from '../settings/styles';
import { MuiThemeProvider, Typography, WithStyles, Button, CssBaseline, Paper, Avatar, FormControl, InputLabel, Input } from '@material-ui/core';
import { translations } from '../settings/translations';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Redirect } from 'react-router';
import DB from '../logic/db';


interface State
{
    code: string,
    password: string,
    failed: boolean,
    failMsg: string,
    redirect: boolean,
    redirectUrl: string
}

// ----------------- Page ------------------  //

class Login extends React.Component<WithStyles<typeof styles>, State> 
{
  db: DB;

  constructor(props: any)
  {
    super(props);

    this.db = new DB();
    this.state = {
        code: '',
        password: '',
        failed: false,
        failMsg: '',
        redirect: false,
        redirectUrl: ""
    }
    this.submit = this.submit.bind(this);
    this.register = this.register.bind(this);
    this.reset = this.reset.bind(this);
    this.handleLogin = this.handleLogin.bind(this);

    window.sessionStorage['uf-teacher-login'] = "{}";
  }

  render() 
  {
    const { classes } = this.props;
    const t = translations[defaultTheme.default.language];

    if(this.state.redirect) 
    {
        return <Redirect push to={this.state.redirectUrl} />
    }

    return (
      <MuiThemeProvider theme={defaultTheme}>
        <main className={classes.login_main}>
            
            <CssBaseline />
            <Paper className={classes.login_paper}>
            <Typography className={classes.login_title}>
                {t.login.title}
            </Typography>
                <Avatar className={classes.login_avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {t.login.box_title}
                </Typography>
                <form className={classes.login_form}>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="code">{t.login.code}</InputLabel>
                        <Input id="code" name="code" autoFocus
                            onChange={event=>{this.setState({code:event.target.value})}} />
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="password">{t.login.password}</InputLabel>
                        <Input name="password" type="password" id="password" autoComplete="current-password"
                            onChange={event=>{this.setState({password:event.target.value})}} />
                    </FormControl>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.login_submit}
                        onClick={this.submit}
                    >
                        {t.login.login_btn}
                    </Button>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.login_register}
                        onClick={this.register}
                    >
                        {t.login.register}
                    </Button>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.login_reset}
                        onClick={this.reset}
                    >
                        {t.login.reset}
                    </Button>
                    {this.failMessage()}
                </form>
            </Paper>
        </main>
      </MuiThemeProvider>
    );
  }

  failMessage()
    {
        const { classes } = this.props;
        if(this.state.failed)
        {
            return (
                <Typography className={classes.register_fail_msg}>
                    {this.state.failMsg}
                </Typography>
            );
        }
    }

  submit()
  {
    let params = {
        code: this.state.code,
        password: this.state.password
    }

    if( params.code !== '' &&
        params.password !== '')
    {
        this.setState({
            failed: false
        });
        this.db.login(params, this.handleLogin);
    }
    else
    {
        this.setState({
            failed: true,
            failMsg: "Alla fält som är markerade med * måste fyllas" // TODO
        });
    }
  }

  register()
  {
    this.setState({
        redirect: true,
        redirectUrl: "/Register"
    });
  }

  reset()
  {
    this.setState({
        redirect: true,
        redirectUrl: "/Reset"
    });
  }

  handleLogin(login: boolean)
  {
    if(login)
    {
        window.sessionStorage['uf-teacher-login'] = JSON.stringify({
            code: this.state.code,
            password: this.state.password
        });
        this.setState({
            redirect: true,
            redirectUrl: "/ControlPanel"
        });
    }
    else
    {
        this.setState({
            failed: true,
            failMsg: "Inloggningen misslyckades" // TODO
        });
    }
  }
}

// ----------------- Export -----------------  //

export default withStyles(Login);

// ------------------------------------------  //