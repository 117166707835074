// ======================================== //
//                                          //
//      Company:    Tar Valley              //
//      Author:     Sebastian Salonen       //
//                                          //
//                © 2019                    //
//                                          //
// ======================================== //

// **** ACTIONS **** //
export interface DBActions
{
    [key: string]: DBAction
}
export interface DBAction
{
    action: string;
    auth: string;
    params: any;
}
// ****** KEYS ****** //
export interface DBKey
{
    id: number;
    mail: string
    keycode: string;
    resetcode: string;
    class_id: string;
    claimed: string;
    created: string;
}
export interface DBKeysCallback
{
    (keys: DBKey[]): void;
}
export interface DBKeyCallback
{
    (key: DBKey): void;
}
export interface DBActionCallback
{
    (success: boolean): void;
}
// **** STUDENTS **** //
export interface DBStudent
{
    id: number;
    class_id: string;
    student_id: string;
    notes: string;
    avatar_head: number;
    avatar_body: number;
    avatar_skin: number;
    register_date: string;
}
export interface DBStudentsCallback
{
    (students: DBStudent[] | undefined): void;
}
export interface DBStudentCallback
{
    (student: DBStudent): void;
}
// ****************** //
export interface DBValueCallback
{
    (value: string): void;
}
export type DBCallback = DBKeyCallback | DBKeysCallback | DBActionCallback | DBStudentCallback | DBStudentsCallback | DBValueCallback;
// ****************** //



export default class DB 
{
    // ======================================== //
    //                  Vars                    //
    // ======================================== //

    static settings: any | undefined;
    actions: DBActions;
    headers: any;

    // ======================================== //
    //                  Init                    //
    // ======================================== //

    // Actions
    constructor()
    {
        this.actions = {
            createKey: {
                action: 'create_key',
                params: {}
            } as DBAction,
            fetchKeys: {
                action: 'read_keys',
                params: {}
            } as DBAction,
            fetchRegisteredClasses: {
                action: 'read_registered_classes',
                params: {}
            } as DBAction,
            fetchRegisteredStudents: {
                action: 'read_registered_students',
                params: {}
            } as DBAction,
            deleteKey: {
                action: 'delete_key',
                params: {
                    key: ''
                }
            } as DBAction,
            freeKey: {
                action: 'free_key',
                params: {
                    key: '',
                    resetkey: ''
                }
            } as DBAction,
            claimKey: {
                action: 'claim_key',
                params: {
                    key: '',
                    className: ''
                }
            } as DBAction,
            registerKey: {
                action: 'register_key',
                params: {
                    key: '',
                    password: '',
                    mail: '',
                    className: ''
                }
            } as DBAction,
            login: {
                action: 'login',
                params: {
                    key: '',
                    password: ''
                }
            } as DBAction,
            adminLogin: {
                action: 'admin_login',
                params: {
                    key: '',
                    password: ''
                }
            } as DBAction,
            fetchStudents: {
                action: 'read_students',
                params: {
                    key: '',
                    password: ''
                }
            } as DBAction,
            getClass: {
                action: 'get_class_name',
                params: {
                    key: '',
                    password: ''
                }
            } as DBAction,
            editClass: {
                action: 'edit_class',
                params: {
                    key: '',
                    password: '',
                    className: ''
                }
            } as DBAction,
            editStudent: {
                action: 'edit_student',
                params: {
                    key: '',
                    password: '',
                    student_id: '',
                    notes: ''
                }
            } as DBAction,
            resetStudent: {
                action: 'reset_student',
                params: {
                    key: '',
                    password: '',
                    student_id: ''
                }
            } as DBAction
        }
    }

    // ======================================== //
    //                Functions                 //
    // ======================================== //

    fetchKeys(params: any, callback: DBKeysCallback)
    {
        this.apiRequest(this.actions.fetchKeys, params, callback);
    }

    // ======================================== //

    fetchRegisteredClasses(params: any, callback: DBValueCallback)
    {
        this.apiRequest(this.actions.fetchRegisteredClasses, params, callback);
    }

    // ======================================== //

    fetchRegisteredStudents(params: any, callback: DBValueCallback)
    {
        this.apiRequest(this.actions.fetchRegisteredStudents, params, callback);
    }

    // ======================================== //

    createKey(params: any, callback: DBKeyCallback)
    {
        this.apiRequest(this.actions.createKey, params, callback);
    }

    // ======================================== //

    deleteKey(params: any, callback: DBKeyCallback)
    {
        this.apiRequest(this.actions.deleteKey, params, callback);
    }

    // ======================================== //

    freeKey(params: any, callback: DBKeyCallback)
    {
        this.apiRequest(this.actions.freeKey, params, callback);
    }

    // ======================================== //

    registerKey(params: any, callback: DBKeyCallback)
    {
        this.apiRequest(this.actions.registerKey, params, callback);
    }

    // ======================================== //

    claimKey(params: any, callback: DBKeyCallback)
    {
        this.apiRequest(this.actions.claimKey, params, callback);
    }

    // ======================================== //

    login(params: any, callback: DBActionCallback)
    {
        this.apiRequest(this.actions.login, params, callback)
    }

    // ======================================== //

    adminLogin(params: any, callback: DBActionCallback)
    {
        this.apiRequest(this.actions.adminLogin, params, callback)
    }

    // ======================================== //

    fetchStudents(params: any, callback: DBStudentsCallback)
    {
        this.apiRequest(this.actions.fetchStudents, params, callback);
    }

    // ======================================== //

    getClass(params: any, callback: DBValueCallback)
    {
        this.apiRequest(this.actions.getClass, params, callback);
    }

    // ======================================== //

    editClass(params: any, callback: DBActionCallback)
    {
        this.apiRequest(this.actions.editClass, params, callback);
    }

    // ======================================== //

    editStudent(params: any, callback: DBActionCallback)
    {
        this.apiRequest(this.actions.editStudent, params, callback);
    }

    // ======================================== //

    resetStudent(params: any, callback: DBActionCallback)
    {
        this.apiRequest(this.actions.resetStudent, params, callback);
    }

    // ======================================== //
    //                Callback                  //
    // ======================================== //

    private handleCallback(event: any, callback: DBCallback)
    {
        var data = event.data.split("\n");
        var action = data.splice(0,1)[0];

        switch(action)
        {
            case this.actions.fetchKeys.action:
                this.handleFetchKeys(data, callback as DBKeysCallback); 
                break;
            case this.actions.fetchRegisteredClasses.action:
                this.handleValue(data[0], callback as DBValueCallback); 
                break;
            case this.actions.fetchRegisteredStudents.action:
                this.handleValue(data[0], callback as DBValueCallback); 
                break;
            case this.actions.createKey.action:
                this.handleCreateKey(data, callback as DBKeyCallback);
                break;
            case this.actions.deleteKey.action:
                this.handleDeleteKey(data[0], callback as DBKeyCallback);
                break;
            case this.actions.freeKey.action:
                this.handleFreeKey(data[0], callback as DBKeyCallback);
                break;
            case this.actions.claimKey.action:
                this.handleClaimedKey(data[0], callback as DBKeyCallback);
                break;
            case this.actions.registerKey.action:
                this.handleRegisteredKey(data[0], callback as DBKeyCallback);
                break;
            case this.actions.login.action:
                this.handleLogin(data[0], callback as DBActionCallback);
                break;
            case this.actions.adminLogin.action:
                this.handleLogin(data[0], callback as DBActionCallback);
                break;
            case this.actions.fetchStudents.action:
                this.handleFetchedStudents(data, callback as DBStudentsCallback);
                break;
            case this.actions.getClass.action:
                this.handleGetClass(data[0], callback as DBValueCallback);
                break;
            case this.actions.editClass.action:
                this.handleEditClass(data[0], callback as DBActionCallback);
                break;
            case this.actions.editStudent.action:
                this.handleEditStudent(data[0], callback as DBActionCallback);
                break;
            case this.actions.resetStudent.action:
                this.handleResetStudent(data[0], callback as DBActionCallback);
                break;
            default:
                console.log("db action failed");
                break;
        }
    }

    // ======================================== //

    private handleFetchKeys(rows: any, callback: DBKeysCallback)
    {
        if(rows.length <= 1) return;

        let keys: DBKey[] = [];
        for(var i = 1; i < rows.length; i++)
        {
            var rowData = rows[i].split(",");
            keys.push({
                id: rowData[0],
                mail: rowData[1],
                keycode: rowData[2],
                resetcode: rowData[3],
                class_id: rowData[4],
                claimed: rowData[5],
                created: rowData[6]
            } as DBKey);
        }
        callback(keys);
    }

    // ======================================== //

    private handleValue(value: string, callback: DBValueCallback)
    {
        callback(value);
    }

    // ======================================== //

    private handleCreateKey(rows: any, callback: DBKeyCallback)
    {
        if(rows.length <= 0) return;

        let key = rows[0].split(",");
        callback({
            id: key[0],
            mail: key[1],
            keycode: key[2],
            resetcode: key[3],
            class_id: key[4],
            claimed: key[5],
            created: key[6]
        } as DBKey);
    }

    // ======================================== //

    private handleDeleteKey(key: string, callback: DBKeyCallback)
    {
        if(key === undefined || key === '') return;

        callback({
            id: {},
            mail: {},
            keycode: key,
            resetcode: {},
            class_id: {},
            claimed: {},
            created: {}
        } as DBKey);
    }

    // ======================================== //

    private handleFreeKey(key: string, callback: DBKeyCallback)
    {
        if(key === undefined || key === '') {
            callback({} as DBKey)
            return;
        } 
        callback({
            id: {},
            mail: {},
            keycode: key,
            resetcode: {},
            class_id: {},
            claimed: {},
            created: {}
        } as DBKey);
    }

    // ======================================== //

    private handleClaimedKey(key: string, callback: DBKeyCallback)
    {
        if(key === undefined || key === '') return;

        callback({
            id: {},
            mail: {},
            keycode: key,
            resetcode: {},
            class_id: {},
            claimed: {},
            created: {}
        } as DBKey);
    }

    // ======================================== //

    private handleRegisteredKey(key: string, callback: DBKeyCallback)
    {
        if(key === undefined) return;

        callback({
            id: {},
            mail: {},
            keycode: key,
            resetcode: {},
            class_id: {},
            claimed: {},
            created: {}
        } as DBKey);
    }

    // ======================================== //

    private handleLogin(success: string, callback: DBActionCallback)
    {
        callback(success==='true');
    }

    // ======================================== //

    private handleFetchedStudents(rows: any, callback: DBStudentsCallback)
    {
        if(rows.length <= 0)
        {
            callback(undefined);
            return;
        }

        let students: DBStudent[] = [];
        for(var i = 1; i < rows.length; i++)
        {
            var rowData = rows[i].split(",");
            students.push({
                id: rowData[0],
                class_id: rowData[1],
                student_id: rowData[2],
                avatar_head: rowData[3],
                avatar_body: rowData[4],
                avatar_skin: rowData[5],
                notes: rowData[6],
                register_date: rowData[7]
            } as DBStudent);
        }
        callback(students);
    }

    // ======================================== //

    private handleGetClass(value: string, callback: DBValueCallback)
    {
        callback(value);
    }

    // ======================================== //

    private handleEditClass(success: string, callback: DBActionCallback)
    {
        callback(success==='true');
    }

    // ======================================== //

    private handleEditStudent(success: string, callback: DBActionCallback)
    {
        callback(success==='true');
    }

    // ======================================== //

    private handleResetStudent(success: string, callback: DBActionCallback)
    {
        callback(success==='true');
    }
    
    // ======================================== //
    //              API Requests                //
    // ======================================== //

    apiRequest(apiCommand: DBAction, params: any, callback: DBCallback)
    {
        let sendRequest = () => {
            apiCommand.params = params;
            let ws = new WebSocket("wss://"+DB.settings.db_server+":"+DB.settings.db_port);
            ws.onopen = function(event)
            {
                ws.send(JSON.stringify({
                    action: apiCommand.action,
                    params: apiCommand.params
                }));
            };
            ws.onclose = function(event)
            {

            };
            ws.onmessage = (event: any)=>
            {
                this.handleCallback(event, callback);
            };
        }

        // Fetch server settings if we don't have them
        if(DB.settings !== undefined)
        {
            sendRequest();
        }
        else
        {
            fetch("./db_settings.json").then( (response: Response) => {
                return response.json();
            }).then( (json: any) => {
                DB.settings = json;
                sendRequest();
            });
        }
    }

    // ======================================== //

}