import * as React from 'react';
import { styles, defaultTheme, withStyles } from '../settings/styles';
import { MuiThemeProvider, Typography, WithStyles, Button, CssBaseline, Paper, FormControl, InputLabel, Input } from '@material-ui/core';
import { translations } from '../settings/translations';
import { Redirect } from 'react-router';
import DB, { DBKey } from '../logic/db';


interface State
{
    keycode: string,
    password: string,
    password2: string,
    mail: string,
    className: string,
    failed: boolean,
    failMsg: string,
    redirect: boolean,
    redirectUrl: string
}

// ----------------- Page ------------------  //

class Register extends React.Component<WithStyles<typeof styles>, State>
{
    db: DB;

    constructor(props: any)
    {
        super(props);

        this.db = new DB();
        this.state = {
            keycode: '',
            password: '',
            password2: '',
            mail: '',
            className: '',
            failed: false,
            failMsg: '',
            redirect: false,
            redirectUrl: ""
        }
        this.submit = this.submit.bind(this);
        this.back = this.back.bind(this);
        this.handleCreateKey = this.handleCreateKey.bind(this);
        this.failMessage = this.failMessage.bind(this);
    }

    render() 
    {
        const { classes } = this.props;
        const t = translations[defaultTheme.default.language];

        if(this.state.redirect) 
        {
            return <Redirect push to={this.state.redirectUrl} />
        }

        return (
        <MuiThemeProvider theme={defaultTheme}>
            <main className={classes.login_main}>
                
                <CssBaseline />
                <Paper className={classes.login_paper}>
                <Typography className={classes.login_title}>
                    {t.register.title}
                </Typography>
                    <form className={classes.login_form}>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="code">{t.register.input_productkey}</InputLabel>
                            <Input id="code" name="code" autoFocus
                                onChange={event=>{this.setState({keycode:event.target.value})}} />
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel>{t.register.input_mail}</InputLabel>
                            <Input id="class-name" name="class name"
                                onChange={event=>{this.setState({mail:event.target.value})}} />
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="password">{t.register.input_password}</InputLabel>
                            <Input name="password" type="password" id="password" 
                                onChange={event=>{this.setState({password:event.target.value})}}/>
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="password">{t.register.input_password_confirm}</InputLabel>
                            <Input name="password" type="password" id="password-confirm"
                                onChange={event=>{this.setState({password2:event.target.value})}} />
                        </FormControl>
                        <FormControl margin="normal" fullWidth>
                            <InputLabel>{t.register.input_classname}</InputLabel>
                            <Input id="class-name" name="class name" 
                                onChange={event=>{this.setState({className:event.target.value})}}/>
                        </FormControl>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.register_submit}
                            onClick={this.submit}
                        >
                            {t.register.submit_btn}
                        </Button>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.register_back}
                            onClick={this.back}
                        >
                            {t.register.back_btn}
                        </Button>
                        {this.failMessage()}
                    </form>
                </Paper>
            </main>
        </MuiThemeProvider>
        );
    }

    failMessage()
    {
        const { classes } = this.props;
        if(this.state.failed)
        {
            return (
                <Typography className={classes.register_fail_msg}>
                    {this.state.failMsg}
                </Typography>
            );
        }
    }

    submit()
    {
        let params = {
            keycode: this.state.keycode,
            password: this.state.password,
            mail: this.state.mail,
            className: this.state.className
        }

        if( this.state.password !== this.state.password2)
        {
            this.setState({
                failed: true,
                failMsg: "Lösenordet matchar inte" // TODO
            });
        }
        else if( !this.mailCheck(params.mail) )
        {
            this.setState({
                failed: true,
                failMsg: "E-post addressen är inte giltig" // TODO
            });
        }
        else if( this.state.password.length < 6) // TODO
        {
            this.setState({
                failed: true,
                failMsg: "Lösenordet måste ha minst 6 tecken" // TODO
            });
        }
        else if( params.keycode !== '' &&
                 params.password !== '' )
        {
            this.setState({
                failed: false
            });
            this.db.registerKey(params, this.handleCreateKey);
        }
        else
        {
            this.setState({
                failed: true,
                failMsg: "Alla fält som är markerade med * måste fyllas" // TODO
            });
        }
    }

    back()
    {
        this.setState({
            redirect: true,
            redirectUrl: "/Login"
        });
    }

    mailCheck(mail: string)
    {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
        {
            return true;
        }
        return false;
    }

    handleCreateKey(keys: DBKey)
    {
        if(keys.keycode === undefined || keys.keycode === '')
        {
            this.setState({
                failed: true,
                failMsg: "Något gick fel" // TODO
            });
        }
        else if(keys.keycode === "CLAIMED")
        {
            this.setState({
                failed: true,
                failMsg: "Produktnyckeln är redan registrerad" // TODO
            });
        }
        else if(keys.keycode === "EXISTS")
        {
            this.setState({
                failed: true,
                failMsg: "Produktnyckeln du angav är inte giltig" // TODO
            });
        }
        else
        {
            this.setState({
                redirect: true,
                redirectUrl: "/Login"
            });
        }
    }
}

// ----------------- Export -----------------  //

export default withStyles(Register);

// ------------------------------------------  //