
export const translations: any = {

    swe: {
        login: {
            title: "Vårt Samhälle",
            box_title: "Lärarlogin",
            code: "Klasskod eller Produktnyckel",
            password: "Lösenord",
            remember: "Kom ihåg mig",
            register: "Registrera",
            reset: "Återställ",
            login_btn: "Logga in"
        },
        register: {
            title: "Registrera Ny Klass",
            input_productkey: "Produktnyckel",
            input_mail: "E-post",
            input_password: "Lösenord",
            input_password_confirm: "Repetera lösenord",
            input_classname: "Klassnamn (frivilligt)",
            submit_btn: "Slutför",
            back_btn: "Tillbaka"
        },
        reset: {
            title: "Återställ Konto",
            input_productkey: "Produktnyckel",
            input_resetkey: "Återställningsnyckel",
            submit_btn: "Återställ",
            back_btn: "Tillbaka"
        },
        control_panel: {
            default_class_name: "Min Klass",
            class_code: "Klasskod",
            print_btn: "Skriv ut",
            dialog_yes: "Ja",
            dialog_no: "Nej",
            delete_header: "Nollställ Elev?",
            delete_description: "Är du säker att du vill nollställa denna elev? All framgång i spelet kommer att tas bort.",
            edit_student_header: "Anteckningar",
            edit_class_header: "Klassnamn",
            edit_yes: "Ändra",
            edit_no: "Avbryt"
        },
        student_table: {
            id: "ID",
            code: "Elevkod",
            notes: "Anteckningar",
            registered: {
                title: "Registrerad",
                true: "Ja",
                false: "Nej"
            },
            reset: "Återställ"
        },
        admin_login: {
            title: "Vårt Samhälle",
            box_title: "Administratör Inlogg",
            code: "Användare",
            password: "Lösenord",
            login_btn: "Logga in"
        },
        admin_panel: {
            title: "Vårt Samhälle",
            subtitle: "produktnycklar",
            generate_btn: "Generera Ny Nyckel",
            dialog_yes: "Ja",
            dialog_no: "Nej",
            classes_stat: "Antal registrerade klasser",
            students_stat: "Antal registrerade studenter",

            delete_header: "Ta bort nyckel?",
            delete_description: "Är du säker att du vill ta bort den här nyckeln? Detta går inte att ångra.",
            claim_header: "Lås nyckel?",
            claim_description: "Är du säker att du vill låsa nyckeln?",
            unclaim_header: "Lås upp nyckel?",
            unclaim_description: "Är du säker du vill låsa upp denna nyckel? Detta kommer ta bort all data från den nuvarande klassen."
        },
        key_table: {
            id: "ID",
            mail: "E-post",
            key: "Produktnyckel",
            class: "Klasskod",
            reset: "Återställningsnyckel",
            create_date: "Skapad",
            claim_date: "Registrerad",
            claim_btn: "Lås",
            unclaim_btn: "Lås upp",
            delete_btn: "Ta bort",
            not_claimed: "Nej",
            next_page: "Nästa sida",
            prev_page: "Föregående sida"
        }
    },

    en: {
        login: {
            title: "Our Society",
            box_title: "Login",
            code: "Teacher Code",
            password: "Password",
            remember: "Remember me",
            register: "Register",
            reset: "Reset",
            login_btn: "Login"
        },
        register: {
            title: "Register New Class",
            input_productkey: "Product Key",
            input_mail: "E-mail",
            input_password: "Password",
            input_password_confirm: "Repeat password",
            input_classname: "Class Name (optional)",
            submit_btn: "Submit",
            back_btn: "Back"
        },
        reset: {
            title: "Reset Account",
            input_productkey: "Product Key",
            input_resetkey: "Reset Key",
            submit_btn: "Reset",
            back_btn: "Back"
        },
        control_panel: {
            print_btn: "Print",
            dialog_yes: "Yes",
            dialog_no: "No",
            delete_header: "Reset Student?",
            delete_description: "Are you sure you want to reset this student? All progress in the game will be deleted."
        },
        student_table: {
            id: "ID",
            code: "Student Code",
            notes: "Notes",
            registered: {
                title: "Registered",
                true: "Yes",
                false: "No"
            },
            reset: "Reset"
        },
        admin_login: {
            title: "Our Society",
            box_title: "Administrator Login",
            code: "User",
            password: "Password",
            login_btn: "Login"
        },
        admin_panel: {
            title: "Admin",
            generate_btn: "Generate New Key",
            dialog_yes: "Yes",
            dialog_no: "No",

            delete_header: "Remove key?",
            delete_description: "Are you sure you want to remove this key? This can't be undone.",
            claim_header: "Lock key?",
            claim_description: "Are you sure you want to lock this key?",
            unclaim_header: "Unlock key?",
            unclaim_description: "Are you sure you want to unlock this key? This will delete all data in the current class."
        },
        key_table: {
            id: "ID",
            mail: "E-mail",
            key: "Product Key",
            reset: "Reset Key",
            class: "Class Code",
            create_date: "Created",
            claim_date: "Registered",
            claim_btn: "Lock",
            unclaim_btn: "Unlock",
            delete_btn: "Delete",
            not_claimed: "No",
            next_page: "Next page",
            prev_page: "Previous page"
        }
    }

}