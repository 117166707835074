// ======================================== //
//                                          //
//      Company:    Tar Valley              //
//      Author:     Sebastian Salonen       //
//                                          //
//                © 2019                    //
//                                          //
// ======================================== //

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import * as React from 'react';
import IStyleProps, { withStyles, defaultTheme } from '../settings/styles';
import { translations } from '../settings/translations';
import { Button, TablePagination } from '@material-ui/core';

// -------------- Style Properties ---------------  //

export interface IKeyColumn {
  id: number,
  mail: string,
  keycode: string,
  resetcode: string,
  class_id: string,
  create_date: string,
  claim_date?: string
}

export interface IKeyTableProps extends IStyleProps  {
  rows: IKeyColumn[];
  claim: (key: IKeyColumn)=>void;
  unclaim: (key: IKeyColumn)=>void;
  delete: (key: IKeyColumn)=>void;
}

// -------------- Element Definition -------------  //

class KeyTable extends React.Component
{
  props: IKeyTableProps;
  state : {
    page: number,
    rowsPerPage: number,
    rows: IKeyColumn[]
  } = {
    page: 0,
    rowsPerPage: 10,
    rows: []
  }

  constructor(props: IKeyTableProps) 
  {
    super(props);
    this.props = props;
    this.state.rows = this.props.rows;
  }

  render()
  {
    const { classes, rows } = this.props;
    const { page, rowsPerPage } = this.state;
    const t = translations[defaultTheme.default.language];

    return (
      <Paper className={classes.admin_paper}>
        <Table className={classes.admin_table}>
          <TableHead>
            <TableRow>
              <TableCell padding="dense">{t.key_table.id}</TableCell>
              <TableCell align="left">{t.key_table.mail}</TableCell>
              <TableCell align="left">{t.key_table.key}</TableCell>
              <TableCell align="left">{t.key_table.reset}</TableCell>
              <TableCell align="left">{t.key_table.class}</TableCell>
              <TableCell align="left">{t.key_table.create_date}</TableCell>
              <TableCell align="left">{t.key_table.claim_date}</TableCell>
              <TableCell padding="dense" align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => (
                <TableRow key={row.id}>
                  <TableCell padding="dense" component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell align="left">{row.mail}</TableCell>
                  <TableCell align="left">{row.keycode}</TableCell>
                  <TableCell align="left">{row.resetcode}</TableCell>
                  <TableCell align="left">{row.class_id}</TableCell>
                  <TableCell align="left">{row.create_date}</TableCell>
                  <TableCell align="left" className={(row.claim_date===undefined)? 'unclaim_txt':'claim_txt'}>{(row.claim_date===undefined) ? t.key_table.not_claimed : row.claim_date}</TableCell>
                  <TableCell padding="dense" align="right" className={'claim_btn'}>
                    {this.rowButtons(classes, t, row)}
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={rows.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          backIconButtonProps={{
            'aria-label': t.key_table.prev_page,
          }}
          nextIconButtonProps={{
            'aria-label': t.key_table.next_page,
          }}
          onChangePage={(event,page)=>{this.setState({
            page: page
          })}}
          onChangeRowsPerPage={event=>this.setState({
            rowsPerPage: event.target.value
          })}
        />
      </Paper>
    );
  }

  rowButtons(classes: any, t: any, row: IKeyColumn)
  {
    if(row.claim_date === undefined)
    {
      return(
        <div className={classes}>
          <Button variant="contained" color="primary" onClick={()=>{this.props.claim(row)}}>
            {t.key_table.claim_btn}
          </Button>
          <Button variant="contained" color="primary" className={'buttons'} onClick={()=>{this.props.delete(row)}}>
            {t.key_table.delete_btn}
          </Button>
        </div>
      );
    }
    else
    {
      return(
        <div className={classes}>
          <Button variant="contained" color="secondary" onClick={()=>{this.props.unclaim(row)}}>
            {t.key_table.unclaim_btn}
          </Button>
          <Button variant="contained" color="primary" className={'buttons'} onClick={()=>{this.props.delete(row)}}>
            {t.key_table.delete_btn}
          </Button>
        </div>
      );
    }
  }
}

// -------------------- Export -------------------  //

//const KeyTable
export default withStyles(KeyTable);

// -----------------------------------------------  //