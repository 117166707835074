// ======================================== //
//                                          //
//      Company:    Tar Valley              //
//      Author:     Sebastian Salonen       //
//                                          //
//                © 2019                    //
//                                          //
// ======================================== //

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';

import * as React from 'react';
import IStyleProps, { withStyles, defaultTheme } from '../settings/styles';
import { translations } from '../settings/translations';
import { Button } from '@material-ui/core';

// -------------- Style Properties ---------------  //

export interface IStudentColumn {
  id: number,
  classId: string,
  studentId: string,
  notes: string,
  registered: boolean,
  registerDate?: string
}

export interface IStudentTableProps extends IStyleProps {
  rows: IStudentColumn[];
  edit: (student: IStudentColumn)=>void;
  reset: (student: IStudentColumn)=>void;
}

// -------------- Element Definition -------------  //

function StudentTable_(props: IStudentTableProps)
{
  const { classes, rows } = props;
  const t = translations[defaultTheme.default.language];

  return (
    <Paper className={classes.cp_paper}>
      <Table className={classes.cp_table}>
        <TableHead>
          <TableRow>
            <TableCell padding="dense">{t.student_table.id}</TableCell>
            <TableCell align="left">{t.student_table.code}</TableCell>
            <TableCell align="left">
              {t.student_table.notes}
            </TableCell>
            <TableCell align="left">{t.student_table.registered.title}</TableCell>
            <TableCell padding="dense" align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id}>
              <TableCell padding="dense" component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell align="left">{row.studentId}</TableCell>
              <TableCell align="left">
                <EditIcon onClick={()=>{props.edit(row)}} style={{marginRight:'1vw',fontSize:'15px'}} />
                {row.notes}
              </TableCell>
              <TableCell align="left" className={row.registered? 'registered_txt':'notregistered_txt'}>{row.registered ? row.registerDate : t.student_table.registered.false}</TableCell>
              <TableCell padding="dense" align="right" className={'reset_btn'}>
                {resetBtn(props, row)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

function resetBtn(props: IStudentTableProps, row: IStudentColumn)
{
  const { classes } = props;
  const t = translations[defaultTheme.default.language];

  if(row.registered)
  {
    return(
      <Button onClick={()=>{props.reset(row)}} variant="contained" color="primary" className={classes.st_reset_btn}>
        {t.student_table.reset}
      </Button>
    );
  }
  else
  {
    return;
  }
}

// -------------------- Export -------------------  //

const StudentTable = withStyles(StudentTable_)
export default StudentTable;

// -----------------------------------------------  //