import * as React from 'react';
import * as ReactDOM from 'react-dom';
import ControlPanel from './pages/control-panel';
import Login from './pages/login';
import Register from './pages/register';
import Reset from './pages/reset';
import AdminLogin from './pages/admin-login';
import AdminPanel from './pages/admin-panel';
import { HashRouter as Router, Route } from "react-router-dom";


ReactDOM.render( 
    <Router basename="/">
        <div>
            <Route path="/Login" component={Login} />
            <Route path="/ControlPanel" component={ControlPanel} />
            <Route path="/Register" component={Register} />
            <Route path="/Reset" component={Reset} />

            <Route path="/Admin" component={AdminLogin} />
            <Route path="/AdminPanel" component={AdminPanel} />
        </div>
    </Router>, 
    document.querySelector('#root')
);