import * as React from 'react';
import { styles, defaultTheme, withStyles } from '../settings/styles';
import { MuiThemeProvider, Typography, WithStyles, Button, CssBaseline, Paper, Avatar, FormControl, InputLabel, Input } from '@material-ui/core';
import { translations } from '../settings/translations';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Redirect } from 'react-router';
import DB from '../logic/db';


interface State
{
    username: string,
    password: string,
    failed: boolean,
    failMsg: string,
    redirect: boolean,
    redirectUrl: string
}

// ----------------- Page ------------------  //

class AdminLogin extends React.Component<WithStyles<typeof styles>, State>
{
    db: DB;

    constructor(props: any)
    {
        super(props);
        this.db = new DB();

        this.state = {
            username: "",
            password: "",
            failed: false,
            failMsg: '',
            redirect: false,
            redirectUrl: ""
        }
        this.submit = this.submit.bind(this);
        this.handleLogin = this.handleLogin.bind(this);

        window.sessionStorage['uf-admin-login'] = "{}";
    }

    render() 
    {
        const { classes } = this.props;
        const t = translations[defaultTheme.default.language];

        if(this.state.redirect) 
        {
            return <Redirect push to={this.state.redirectUrl} />
        }

        return (
        <MuiThemeProvider theme={defaultTheme}>
            <main className={classes.login_main}>
                
                <CssBaseline />
                <Paper className={classes.login_paper}>
                <Typography className={classes.login_title}>
                    {t.admin_login.title}
                </Typography>
                    <Avatar className={classes.login_avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t.admin_login.box_title}
                    </Typography>
                    <form className={classes.login_form}>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="username">{t.admin_login.code}</InputLabel>
                            <Input id="code" name="code" onChange={event=>{this.setState({username:event.target.value})}} value={this.state.username} autoFocus />
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="password">{t.admin_login.password}</InputLabel>
                            <Input name="password" type="password" id="password" onChange={event=>{this.setState({password:event.target.value})}} value={this.state.password} autoComplete="current-password" />
                        </FormControl>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.login_submit}
                            onClick={this.submit}
                        >
                            {t.admin_login.login_btn}
                        </Button>
                        {this.failMessage()}
                    </form>
                </Paper>
            </main>
        </MuiThemeProvider>
        );
    }

    failMessage()
    {
        const { classes } = this.props;
        if(this.state.failed)
        {
            return (
                <Typography className={classes.register_fail_msg}>
                    {this.state.failMsg}
                </Typography>
            );
        }
    }

    submit()
    {
        let params = {
            username: this.state.username,
            password: this.state.password
        }

        if( params.username !== '' &&
            params.password !== '')
        {
            this.setState({
                failed: false
            });
            this.db.adminLogin(params, this.handleLogin);
        }
        else
        {
            this.setState({
                failed: true,
                failMsg: "Alla fält som är markerade med * måste fyllas" // TODO
            });
        }
    }

    handleLogin(login: boolean)
    {
        if(login)
        {
            window.sessionStorage['uf-admin-login'] = JSON.stringify({
                username: this.state.username,
                password: this.state.password
            });
            this.setState({
                redirect: true,
                redirectUrl: "/AdminPanel"
            });
        }
        else
        {
            this.setState({
                failed: true,
                failMsg: "Inloggningen misslyckades" // TODO
            });
        }
    }
}

// ----------------- Export -----------------  //

export default withStyles(AdminLogin);

// ------------------------------------------  //