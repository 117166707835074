import { 
    createMuiTheme, 
    createStyles, Theme,
    WithStyles as _WithStyles,
    withStyles as _withStyles,
    StyleRulesCallback
} from '@material-ui/core/styles';

// ---------------- Themes ------------------ //

export interface IDefaultTheme extends Theme {
  default : {
    language: string
  }
}

export const defaultTheme : IDefaultTheme = {
  ...createMuiTheme({
    typography: {
      fontFamily : '"Roboto", sans-serif;',
      useNextVariants: true
    }
  }),

  default : {
    language: "swe"
  }
}

// ---------------- Style ------------------ //

export const styles: StyleRulesCallback = (theme : Theme) => {

    return createStyles({

      // -------------- Admin Panel -------------- //

      admin_root: {
        overflowX: 'auto',
        overflowY: 'auto',
        width: '100%',
        display: 'block',
        height: '100vh',
        marginBottom: '100px'
      },

      admin_title: {
        marginTop: theme.spacing.unit * 3,
        fontSize : '2rem',
        textAlign: 'center'
      },

      admin_subtitle: {
        marginTop: theme.spacing.unit * 0.5,
        marginBottom: theme.spacing.unit * 5,
        fontSize : '1.3rem',
        textAlign: 'center'
      },

      admin_stat: {
        marginTop: theme.spacing.unit * 0.5,
        marginBottom: theme.spacing.unit * 0.5,
        fontSize : '1rem',
        textAlign: 'center'
      },
      
      admin_paper: {
        marginTop: theme.spacing.unit * 1,
        position: 'relative',
        overflowX: 'auto',
        width: '96%',
        left: '2%',
        right: '2%',

        '& .buttons' : {
          marginLeft: '5px'
        },

        '& .claim_txt': {
          color: 'red'
        },

        '& .unclaim_txt': {
          color: 'green'
        }
      },

      admin_table: {
        minWidth: 1300
      },

      admin_generate_btn: {
        textAlign: 'center',
        marginTop: theme.spacing.unit * 6,
        width: '20%',
        left: '40%',
        right: '40%'
      },

      // -------------- Control Panel -------------- //

      cp_root: {
        overflowX: 'auto',
        overflowY: 'auto',
        width: '100%',
        display: 'block',
        height: '100vh',
        marginBottom: '100px'
      },

      cp_title: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 1,
        fontSize : '3rem',
        textAlign: 'center'
      },

      cp_subtitle: {
        marginTop: theme.spacing.unit * 1,
        marginBottom: theme.spacing.unit * 10,
        fontSize : '2rem',
        textAlign: 'center'
      },

      cp_edit_class_name_btn: {
        marginLeft: '2vw'
      },

      cp_edit_notes_btn: {
        width: '20px',
        height: '20px',
        fontSizeSmall: '20px'
      },
      cp_edit_notes_icon: {
        marginLeft: '2vw',
        fontSize: '3px',
        fontSizeSmall: '3px'
      },
      
      cp_logout: {
        position: 'absolute',
        marginTop: theme.spacing.unit * 4,
        marginLeft: '2vw'
      },
      
      cp_paper: {
        position: 'relative',
        overflowX: 'auto',
        overflowY: 'auto',
        width: '96%',
        left: '2%',
        right: '2%',

        '& .reset_btn' : {
          width : '1px'
        },

        '& .registered_txt': {
          color: 'red'
        },

        '& .notregistered_txt': {
          color: 'green'
        }
      },

      cp_table: {
        minWidth: 400
      },

      cp_print_btn: {
        textAlign: 'center',
        marginTop: theme.spacing.unit * 6,
        width: '10%',
        left: '45%',
        right: '45%'
      },

      // ----------------- Login ----------------- //

      login_main: {
        overflowX: 'auto',
        overflowY: 'auto',
        width: '100%',
        display: 'block',
        height: '100vh',
        marginBottom: 'auto',
        marginTop: 'auto',
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
          width: 400,
          marginLeft: 'auto',
          marginRight: 'auto'
        },
      },
      login_title: {
        marginTop: theme.spacing.unit * 1,
        marginBottom: theme.spacing.unit * 5,
        textAlign: 'center',
        fontSize: '2rem'
      },
      login_paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
      },
      login_avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
      },
      login_form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
      },
      login_submit: {
        marginTop: theme.spacing.unit * 4,
      },
      login_register: {
        marginTop: theme.spacing.unit * 2,
      },
      login_reset: {
        marginTop: theme.spacing.unit * 2,
      },

      // ---------------- Register --------------- //

      register_submit: {
        marginTop: theme.spacing.unit * 6,
      },
      register_back: {
        marginTop: theme.spacing.unit * 2,
      },
      register_fail_msg: {
        color: '#ff0000',
        marginTop: theme.spacing.unit * 2,
      },

      // ----------------------------------------- //

      print_page: {
        textAlign: "center"
      }

      // ----------------------------------------- //
    });

}

// ----------------- Export ----------------- //

export default interface IStyleProps extends _WithStyles<typeof styles> {}
export const withStyles = _withStyles(styles);

// ------------------------------------------ //