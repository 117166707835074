// ======================================== //
//                                          //
//      Company:    Tar Valley              //
//      Author:     Sebastian Salonen       //
//                                          //
//                © 2019                    //
//                                          //
// ======================================== //

import * as React from 'react';
import StudentTable, { IStudentColumn } from '../components/student-table';
import { styles, defaultTheme, withStyles } from '../settings/styles';
import { MuiThemeProvider, Typography, WithStyles, Button, Fab, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, Input } from '@material-ui/core';
import { translations } from '../settings/translations';
import EditIcon from '@material-ui/icons/Edit';
import ExitToApp from '@material-ui/icons/ExitToApp';
import DB, { DBStudent } from '../logic/db';
import { Redirect } from 'react-router';
import NewWindow from 'react-new-window';

// -------------- State Data ---------------  //

type State = {
  rows: IStudentColumn[];
  print: boolean;
  class_name: string;
  class_code: string;
  edit_open: boolean;
  dialog_open: boolean;
  dialog_header: string;
  dialog_content: string;
  dialog_callback: (confirmed:boolean)=>void;
  redirect: boolean,
  redirectUrl: string
};

// ----------------- Page ------------------  //

class ControlPanel extends React.Component<WithStyles<typeof styles>, State> 
{
  //========================//
  //          Vars          //
  //========================//
  
  db: DB;
  translation: any;
  loginParams: any;

  //========================//
  //         Routine        //
  //========================//

  constructor(props: any)
  {
    super(props);
    this.db = new DB();
    this.translation = translations[defaultTheme.default.language];

    // Auth
    this.loginParams = window.sessionStorage['uf-teacher-login'] === undefined ? undefined : JSON.parse(window.sessionStorage['uf-teacher-login']);
    let gotLogin = this.loginParams !== undefined && this.loginParams.code !== undefined && this.loginParams.password !== undefined;
    if(gotLogin)
    {
      this.db.fetchStudents(this.loginParams, this.handleStudents.bind(this));
      this.db.getClass(this.loginParams, (value: string)=>{this.setState({class_name: value})});
    }

    // Init State
    this.state = {
      rows: [],
      print: false,
      class_name: "",
      class_code: "",
      edit_open: false,
      dialog_open: false,
      dialog_header: "",
      dialog_content: "",
      dialog_callback: ()=>{},
      redirect: !gotLogin,
      redirectUrl: "/Login"
    }

    // Bind Functions
    this.print = this.print.bind(this);
    this.logout = this.logout.bind(this);
    this.dialogOpen = this.dialogOpen.bind(this);
    this.handleEditClose = this.handleEditClose.bind(this);
    this.handleEditConfirm = this.handleEditConfirm.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleDialogConfirm = this.handleDialogConfirm.bind(this);
    this.handleStudents = this.handleStudents.bind(this);
    this.handleEditStudent = this.handleEditStudent.bind(this);
    this.handleResetStudent = this.handleResetStudent.bind(this);
    this.handleEditClass = this.handleEditClass.bind(this);

    // window.addEventListener("unload", (event: any) => {
    //   window.sessionStorage['uf-teacher-login'] = "{}";
    // });
  }

  //========================//

  render() 
  {
    const { classes } = this.props;
    const t = this.translation;

    if(this.state.redirect) 
    {
        return <Redirect push to={this.state.redirectUrl} />
    }

    return (
      <MuiThemeProvider theme={defaultTheme}>
        <div className={classes.cp_root}>

          <Fab color="secondary" aria-label="Edit" className={classes.cp_logout} onClick={this.logout}>
            <ExitToApp/>
          </Fab>
          <Typography className={classes.cp_title}>
            {this.state.class_name}
            <Fab color="primary" aria-label="Edit" className={classes.cp_edit_class_name_btn} onClick={this.handleEditClass}>
              <EditIcon/>
            </Fab>
          </Typography>
          <Typography className={classes.cp_subtitle}>
            {t.control_panel.class_code+": "+this.state.class_code}
          </Typography>

          <StudentTable  rows={this.state.rows} edit={this.handleEditStudent} reset={this.handleResetStudent} />
    
          <Button onClick={this.print} variant="contained" color="primary" className={classes.cp_print_btn}>
            {t.control_panel.print_btn}
          </Button>

          <Dialog
            open={this.state.dialog_open}
            onClose={this.handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{this.state.dialog_header}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.state.dialog_content}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleDialogClose} color="secondary">
                {t.admin_panel.dialog_no}
              </Button>
              <Button onClick={this.handleDialogConfirm} color="primary" autoFocus>
                {t.admin_panel.dialog_yes}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.edit_open}
            onClose={this.handleEditClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{this.state.dialog_header}</DialogTitle>
            <DialogContent>
              <FormControl margin="normal" fullWidth>
                <Input id="code" name="code" autoFocus value={this.state.dialog_content}
                  onChange={event=>{this.setState({dialog_content:event.target.value})}} />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleEditClose} color="secondary">
                {t.control_panel.edit_no}
              </Button>
              <Button onClick={this.handleEditConfirm} color="primary" autoFocus>
                {t.control_panel.edit_yes}
              </Button>
            </DialogActions>
          </Dialog>

          {this.printwindow()}

          <br></br><br></br><br></br>
        </div>
      </MuiThemeProvider>
    );
  }

  printwindow()
  {
    const { classes } = this.props;

    if(this.state.print)
    {
      return(
        <NewWindow title={this.state.class_name} onUnload={()=>{
          this.setState({
            print: false
          });
        }}>
          <div className={classes.print_page}>
            <table cellSpacing="30">
              <tr>
                <td><h1>{this.state.class_name}</h1></td>
              </tr>
              {this.state.rows.map(row => (
                <tr>
                  <td>{row.notes}</td>
                  <td>Klasskod: <b>{row.classId}</b></td>
                  <td>Elevkod: <b>{row.studentId}</b></td>
                </tr>
              ))}
            </table>
          </div>
        </NewWindow>
      );
    }
    else
    {
      return;
    }
  }

  //========================//
  //      Button Logic      //
  //========================//

  print()
  {
    this.setState({
      print: true
    })
  }

  //========================//

  dialogOpen(header: string, content: string, callback: (confirmed:boolean)=>void)
  {
    this.setState({ 
      dialog_open: true,
      dialog_header: header,
      dialog_content: content,
      dialog_callback: callback
    });
  }

  //========================//

  editOpen(header: string, content: string, callback: (confirmed:boolean)=>void)
  {
    this.setState({ 
      edit_open: true,
      dialog_header: header,
      dialog_content: content,
      dialog_callback: callback
    });
  }

  //========================//

  logout()
  {
    window.sessionStorage['uf-teacher-login'] = "{}";
    this.setState({
      redirect: true,
      redirectUrl: "/Login"
    });
  }

  //========================//
  //        Callbacks       //
  //========================//

  handleEditClass()
  {
    this.editOpen(
      this.translation.control_panel.edit_class_header,
      this.state.class_name,
      (confirmed: boolean)=> {
        if(confirmed) {
          let params = this.loginParams;
          params['className'] = this.state.dialog_content;
          this.db.editClass(params, (success: boolean)=> {
            console.log(success);
            if(success)
            {
              this.setState({
                class_name: this.state.dialog_content === "" ? this.translation.control_panel.default_class_name : this.state.dialog_content
              });
              console.log("edited class name to "+this.state.dialog_content);
            }
          });
        }
      }
    );
  }

  //========================//

  handleResetStudent(student: IStudentColumn)
  {
    this.dialogOpen(
      this.translation.control_panel.delete_header,
      this.translation.control_panel.delete_description,
      (confirmed: boolean)=>{
        if(confirmed){
          let params = this.loginParams;
          params['student_id'] = student.studentId;
          this.db.editClass(params, (success: boolean)=> {
            if(success)
            {
              this.setState({
                class_name: this.state.dialog_content === "" ? this.translation.control_panel.default_class_name : this.state.dialog_content
              });
              console.log("reseted student "+student.id);
            }
          });
        }
      }
    );
  }

  //========================//

  handleEditStudent(student: IStudentColumn)
  {
    this.editOpen(
      this.translation.control_panel.edit_student_header,
      student.notes === '...' ? "" : student.notes,
      (confirmed: boolean) => {
        if(confirmed) {
          let params = this.loginParams;
          params['student_id'] = student.studentId;
          params['notes'] = this.state.dialog_content;
          this.db.editStudent(params, (success: boolean) => {
            if(success)
            {
              student.notes = this.state.dialog_content;
              console.log("edited "+student.id+" "+this.state.dialog_content);
              this.setState({
                rows: this.state.rows
              });
            }
          });
        }
      }
    );
  }

  //========================//

  handleEditClose()
  {
    this.setState({
      edit_open: false
    });
    this.state.dialog_callback(false);
  }

  //========================//

  handleEditConfirm()
  {
    this.setState({ 
      edit_open: false
    });
    this.state.dialog_callback(true);
  }

  //========================//

  handleDialogClose()
  {
    this.setState({
      dialog_open: false
    });
    this.state.dialog_callback(false);
  }

  //========================//

  handleDialogConfirm()
  {
    this.setState({ 
      dialog_open: false
    });
    this.state.dialog_callback(true);
  }

  //========================//

  handleStudents(students: DBStudent[] | undefined)
  {
    if(students === undefined)
    {
      this.setState({
        redirect: true,
        redirectUrl: "/Login"
      });
    }
    else
    {
      let rows:IStudentColumn[] = [];
      students.forEach(student => {
        rows.push({
          id: rows.length+1,
          classId: student.class_id,
          studentId: student.student_id,
          notes: student.notes === 'No' ? "..." : student.notes, //TODO
          registered: student.register_date !== 'No',
          registerDate: student.register_date === 'No' ? "Nej" : student.register_date // TODO
        } as IStudentColumn)
      });
      this.setState({
        rows: rows,
        class_code: rows.length > 0 ? rows[0].classId : ""
      });
    }
  }

  //========================//
}

// ----------------- Export -----------------  //

export default withStyles(ControlPanel);

// ------------------------------------------  //